import * as React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Layout from '../layouts/layout'

const Privacy = () => {
  const { t } = useTranslation();
  const data = {
    "title": t('privacyTitle'),
    "desc": t('aboutDesc'),
    "doc": t('DocPriv'),
  };

  return (
    <Layout>
      <div className="main-content">
        <div className="rwt-split-area">
          <div className="row pb--125">
          </div>
          <div className="container">
            <div className="wrapper">
              <div className="row">
                <div className="col-lg-12">
                  <div className={`section-title text-center`}>
                    <h2 className="title w-600 mb--20" dangerouslySetInnerHTML={{ __html: data.title }}></h2>
                  </div>
                </div>
              </div>

              <div className="row mb--20">
                <p className="description b1 color-gray mb--0"
                   dangerouslySetInnerHTML={{ __html: t('PuffyPrivacyDescription') }}></p>
              </div>
              <div className="row mb--20">
                <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('privacyArticle1') }}></h4>
                <p className="description b1 color-gray mb--20"
                   dangerouslySetInnerHTML={{ __html: t('privacyArticle1Desc') }}></p>
                <div className="mb--20">
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('privacyArticle1H1') }}></h5>
                  <table className="table color-gray text-center">
                    <thead>
                    <th dangerouslySetInnerHTML={{ __html: t('privacyArticle1H1T1A1') }}></th>
                    <th dangerouslySetInnerHTML={{ __html: t('privacyArticle1H1T1B1') }}></th>
                    </thead>
                    <tbody>
                    <tr>
                      <td dangerouslySetInnerHTML={{ __html: t('PuffyPrivacyArticle1H1T1A2') }}></td>
                      <td dangerouslySetInnerHTML={{ __html: t('PuffyPrivacyArticle1H1T1B2') }}></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row mb--20">
                <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('privacyArticle2') }}></h4>
                <p className="description b1 color-gray mb--20"
                   dangerouslySetInnerHTML={{ __html: t('privacyArticle2Desc') }}></p>
                <div className="mb--20">
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('privacyArticle2H1') }}></h5>
                  <table className="table color-gray text-center">
                    <thead>
                    <th colSpan={2} dangerouslySetInnerHTML={{ __html: t('privacyArticle2H1T1A1') }}></th>
                    <th dangerouslySetInnerHTML={ { __html: t('privacyArticle2H1T1C1')}}></th>
                    <th dangerouslySetInnerHTML={ { __html: t('privacyArticle2H1T1D1')}}></th>
                    </thead>
                    <tbody>
                    <tr>
                      <td dangerouslySetInnerHTML={ { __html: t('privacyArticle2H1T1A2') }}></td>
                      <td dangerouslySetInnerHTML={ { __html: t('privacyArticle2H1T1B2') }}></td>
                      <td dangerouslySetInnerHTML={ { __html: t('PuffyPrivacyArticle2H1T1C2') }}></td>
                      <td dangerouslySetInnerHTML={ { __html: t('privacyArticle2H1T1D2') }}></td>
                    </tr>
                    </tbody>
                  </table>

                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={ { __html: t('PuffyPrivacyArticle2H2') }}></h5>
                  <table className="table color-gray text-center">
                    <thead>
                    <th dangerouslySetInnerHTML={ { __html: t('privacyArticle2H2T1A1') }}></th>
                    <th dangerouslySetInnerHTML={ { __html: t('privacyArticle2H2T1B1') }}></th>
                    <th dangerouslySetInnerHTML={ { __html: t('privacyArticle2H2T1C1') }}></th>
                    </thead>
                    <tbody>
                    <tr>
                      <td dangerouslySetInnerHTML={ { __html: t('PuffyPrivacyArticle2H2T1A2') }}></td>
                      <td dangerouslySetInnerHTML={ { __html: t('PuffyPrivacyArticle2H2T1B2') }}></td>
                      <td dangerouslySetInnerHTML={ { __html: t('PuffyPrivacyArticle2H2T1C2') }}></td>
                    </tr>
                    </tbody>
                  </table>
                  <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={ { __html: t('PuffyPrivacyArticle2H2S1') }}></p>
                </div>
                <div className="row mb--20">
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={ { __html: t('privacyArticle2H3') }}></h5>
                </div>
              </div>

              <div className="row mb--20">
                <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('privacyArticle3') }}></h4>
                <p className="description b1 color-gray mb--20"
                   dangerouslySetInnerHTML={{ __html: t('privacyArticle3Desc') }}></p>
                <div className="mb--20">
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={ { __html: t('privacyArticle3H1') }}></h5>
                  <table className="table color-gray text-center">
                    <thead>
                    <th dangerouslySetInnerHTML={ { __html: t('privacyArticle3H1T1A1') }}></th>
                    <th dangerouslySetInnerHTML={ { __html: t('privacyArticle3H1T1B1') }}></th>
                    </thead>
                    <tbody>
                    <tr>
                      <td dangerouslySetInnerHTML={ { __html: t('PuffyPrivacyArticle3H1T1A2') }}></td>
                      <td dangerouslySetInnerHTML={ { __html: t('PuffyPrivacyArticle3H1T1B2') }}></td>
                    </tr>
                    </tbody>
                  </table>
                  <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={ { __html: t('privacyArticle3H1S1') }}></p>
                </div>
                <div className="row mb--20">
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={ { __html: t('privacyArticle3H2')}}></h5>
                  <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={ { __html: t('privacyArticle3H2E1')}}></p>
                  <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={ { __html: t('privacyArticle3H2E2')}}></p>
                  <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={ { __html: t('privacyArticle3H2E3')}}></p>
                </div>
              </div>

              <div className="row mb--20">
                <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('privacyArticle4') }}></h4>
                <p className="description b1 color-gray mb--20"
                   dangerouslySetInnerHTML={{ __html: t('privacyArticle4Desc') }}></p>
              </div>

              <div className="row mb--20">
                <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('privacyArticle5') }}></h4>
                <p className="description b1 color-gray mb--20"
                   dangerouslySetInnerHTML={{ __html: t('privacyArticle5Desc') }}></p>
                <div className="mb--20">
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={ { __html: t('privacyArticle5H1') }}></h5>
                </div>
                <div className="mb--20">
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={ { __html: t('privacyArticle5H2') }}></h5>
                  <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={ { __html: t('privacyArticle5H2E1') }}></p>
                  <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={ { __html: t('privacyArticle5H2E2') }}></p>
                </div>
              </div>

              <div className="row mb--20">
                <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('privacyArticle6') }}></h4>
                <div className="mb--20">
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={ { __html: t('privacyArticle6H1')}}></h5>
                  <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={ { __html: t('privacyArticle6H1E1')}}></p>
                  <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={ { __html: t('privacyArticle6H1E2')}}></p>
                  <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={ { __html: t('privacyArticle6H1E3')}}></p>
                  <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={ { __html: t('privacyArticle6H1E4')}}></p>
                </div>
                <div className="mb--20">
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={ { __html: t('privacyArticle6H2')}}></h5>
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={ { __html: t('privacyArticle6H3')}}></h5>
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={ { __html: t('privacyArticle6H4')}}></h5>
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={ { __html: t('privacyArticle6H5')}}></h5>
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={ { __html: t('privacyArticle6H6')}}></h5>
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={ { __html: t('privacyArticle6H7')}}></h5>
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={ { __html: t('PuffyPrivacyArticle6H8')}}></h5>
                </div>
              </div>

              <div className="row mb--20">
                <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('privacyArticle7') }}></h4>
                <p className="description b1 color-gray mb--20"
                   dangerouslySetInnerHTML={{ __html: t('privacyArticle7Desc') }}></p>
                <div className="mb--20">
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('privacyArticle7H1')}}></h5>
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('privacyArticle7H2')}}></h5>
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('privacyArticle7H3')}}></h5>
                </div>
              </div>

              <div className="row mb--20">
                <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('privacyArticle8') }}></h4>
                <p className="description b1 color-gray mb--20"
                   dangerouslySetInnerHTML={{ __html: t('privacyArticle8Desc') }}></p>
                <div className="mb--20">
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('privacyArticle8H1')}}></h5>
                  <table className="table color-gray text-center">
                    <thead>
                    <th dangerouslySetInnerHTML={{ __html: t('privacyArticle8H1T1A1')}}></th>
                    <th dangerouslySetInnerHTML={{ __html: t('privacyArticle8H1T1B1')}}></th>
                    </thead>
                    <tbody>
                    <tr>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle8H1T1A2')}}></td>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle8H1T1B2')}}></td>
                    </tr>
                    <tr>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle8H1T1A3')}}></td>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle8H1T1B3')}}></td>
                    </tr>
                    <tr>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle8H1T1A4')}}></td>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle8H1T1B4')}}></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div className="mb--20">
                  <h5 className="w-400 mb--20" dangerouslySetInnerHTML={{ __html: t('privacyArticle8H2')}}></h5>
                </div>
              </div>

              <div className="row mb--20">
                <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('privacyArticle9') }}></h4>
                <div className="mb--20">
                  <table className="table color-gray text-center">
                    <thead>
                    <th dangerouslySetInnerHTML={{ __html: t('privacyArticle9T1A1')}}></th>
                    </thead>
                    <tbody>
                    <tr>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle9T1A2')}}></td>
                    </tr>
                    <tr>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle9T1A3')}}></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row mb--20">
                <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('privacyArticle10') }}></h4>
                <p className="description b1 color-gray mb--20"
                   dangerouslySetInnerHTML={{ __html: t('privacyArticle10Desc') }}></p>
                <div className="mb--20">
                  <table className="table color-gray text-center align-middle">
                    <tbody>
                    <tr>
                      <td rowSpan={4} dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1A1')}}></td>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1B1')}}></td>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1C1')}}></td>
                    </tr>
                    <tr>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1B2')}}></td>
                      <td><a href="https://privacy.kisa.or.kr">privacy.kisa.or.kr</a></td>
                    </tr>
                    <tr>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1B3')}}></td>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1C3')}}></td>
                    </tr>
                    <tr>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1B4')}}></td>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1C4')}}></td>
                    </tr>
                    <tr>
                      <td rowSpan={4} dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1A5')}}></td>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1B5')}}></td>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1C5')}}></td>
                    </tr>
                    <tr>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1B6')}}></td>
                      <td><a href="https://www.kopico.go.kr">www.kopico.go.kr</a></td>
                    </tr>
                    <tr>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1B7')}}></td>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1C7')}}></td>
                    </tr>
                    <tr>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1B8')}}></td>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1C8')}}></td>
                    </tr>
                    <tr>
                      <td rowSpan={2} dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1A9')}}></td>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1B9')}}></td>
                      <td><a href="https://www.spo.go.kr">www.spo.go.kr</a></td>
                    </tr>
                    <tr>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1B10')}}></td>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1C10')}}></td>
                    </tr>
                    <tr>
                      <td rowSpan={2} dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1A11')}}></td>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1B11')}}></td>
                      <td><a href="https://cyberbureau.police.go.kr">cyberbureau.police.go.kr</a></td>
                    </tr>
                    <tr>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1B12')}}></td>
                      <td dangerouslySetInnerHTML={{ __html: t('privacyArticle10T1C12')}}></td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row mb--20">
                <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('privacyArticle11') }}></h4>
                <p className="description b1 color-gray mb--20"
                   dangerouslySetInnerHTML={{ __html: t('privacyArticle11Desc') }}></p>
              </div>

              <div className="row mb--20">
                <h4 className="row w-200 mb--20" dangerouslySetInnerHTML={{ __html: t('privacyArticle12') }}></h4>
                <p className="description b1 color-gray mb--20"
                   dangerouslySetInnerHTML={{ __html: t('privacyArticle12Desc') }}></p>
              </div>

              <div className="row mb--0">
                <p className="row w-200 mb--20 b1 color-white" dangerouslySetInnerHTML={{ __html: t('PuffyPrivacyApply') }}></p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Privacy
